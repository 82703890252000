import {
  GET_CONTENTS,
  GET_CONTENTS_ERROR,
  GET_CONTENTS_SUCCESS,
  DELETE_CONTENT,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_ERROR,
  UPDATE_CONTENT,
  UPDATE_CONTENT_ERROR,
  UPDATE_CONTENT_SUCCESS,
  CREATE_CONTENT,
  CREATE_CONTENT_ERROR,
  CREATE_CONTENT_SUCCESS,
  CLEAR_CONTENT_TARGET_STATUS,
} from './constants'

export const getContents = ({ targetId, contentType }) => ({
  type: GET_CONTENTS,
  targetId,
  contentType,
})

export const getContentsError = ({ error, targetId, contentType }) => ({
  type: GET_CONTENTS_ERROR,
  error,
  targetId,
  contentType,
})

export const getContentsSuccess = ({ targetId, contentType, contents }) => ({
  type: GET_CONTENTS_SUCCESS,
  contents,
  targetId,
  contentType,
})

export const deleteContent = ({
  contentId,
  targetId,
  contentType,
  parentTargetId,
}) => ({
  type: DELETE_CONTENT,
  contentId,
  targetId,
  contentType,
  parentTargetId,
})

export const deleteContentError = ({
  contentId,
  error,
  targetId,
  contentType,
  parentTargetId,
}) => ({
  type: DELETE_CONTENT_ERROR,
  contentId,
  error,
  targetId,
  contentType,
  parentTargetId,
})

export const deleteContentSuccess = ({
  contentId,
  targetId,
  contentType,
  parentTargetId,
}) => ({
  type: DELETE_CONTENT_SUCCESS,
  contentId,
  targetId,
  contentType,
  parentTargetId,
})

export const updateContent = ({ contentId, data, contentType, targetId }) => ({
  type: UPDATE_CONTENT,
  contentId,
  data,
  contentType,
  targetId,
})

export const updateContentError = ({
  contentId,
  contentType,
  error,
  targetId,
}) => ({
  type: UPDATE_CONTENT_ERROR,
  contentId,
  contentType,
  error,
  targetId,
})

export const updateContentSuccess = ({
  contentId,
  data,
  contentType,
  targetId,
}) => ({
  type: UPDATE_CONTENT_SUCCESS,
  contentId,
  contentType,
  targetId,
  data,
})

export const createContent = ({
  contentType,
  targetId,
  data,
  temporaryContentId,
  parentTargetId,
  dimensionValueIds,
}) => ({
  type: CREATE_CONTENT,
  contentType,
  targetId,
  data,
  temporaryContentId,
  parentTargetId,
  dimensionValueIds,
})

export const createContentError = ({
  contentType,
  error,
  targetId,
  temporaryContentId,
  parentTargetId,
}) => ({
  type: CREATE_CONTENT_ERROR,
  contentType,
  error,
  targetId,
  temporaryContentId,
  parentTargetId,
})

export const createContentSuccess = ({
  content,
  contentType,
  targetId,
  temporaryContentId,
  parentTargetId,
}) => ({
  type: CREATE_CONTENT_SUCCESS,
  content,
  contentType,
  targetId,
  temporaryContentId,
  parentTargetId,
})

export const clearContentTargetStatus = ({
  contentType,
  targetId,
  parentTargetId,
}) => ({
  type: CLEAR_CONTENT_TARGET_STATUS,
  contentType,
  targetId,
  parentTargetId,
})

/*
 *
 * ReportRollingRules actions
 *
 */

import {
  CREATE_REPORT_ROLLING_RULE,
  GET_REPORT_ROLLING_RULES,
  GET_REPORT_ROLLING_RULES_SUCCESS,
  GET_REPORT_ROLLING_RULES_ERROR,
  DELETE_REPORT_ROLLING_RULE,
  DELETE_REPORT_ROLLING_RULE_SUCCESS,
  CREATE_REPORT_ROLLING_RULE_SUCCESS,
  CREATE_REPORT_ROLLING_RULE_ERROR,
  DELETE_ALL_REPORT_ROLLING_RULES,
  DELETE_ALL_REPORT_ROLLING_RULES_SUCCESS,
  DELETE_ALL_REPORT_ROLLING_RULES_ERROR,
  SET_SHOW_REPORT_ROLLING_RULE_MODAL,
  UPDATE_REPORT_ROLLING_RULE,
  UPDATE_REPORT_ROLLING_RULE_SUCCESS,
  UPDATE_REPORT_ROLLING_RULE_ERROR,
} from './constants'

export const createReportRollingRule = ({
  companyId,
  reportId,
  sourceDimensionValueIds,
  destinationDimensionValues,
  reportSchemeRow,
  realBalanceIds,
  operativeItemIds,
  values,
  startDate,
  endDate,
  datasourceId,
  accountId,
}) => ({
  type: CREATE_REPORT_ROLLING_RULE,
  companyId,
  reportId,
  sourceDimensionValueIds,
  destinationDimensionValues,
  reportSchemeRow,
  realBalanceIds,
  operativeItemIds,
  values,
  startDate,
  endDate,
  datasourceId,
  accountId,
})

export const getReportRollingRules = (reportId) => ({
  type: GET_REPORT_ROLLING_RULES,
  reportId,
})
export const getReportRollingRulesSuccess = (rollingRules) => ({
  type: GET_REPORT_ROLLING_RULES_SUCCESS,
  rollingRules,
})

export const getReportRollingRulesError = (error) => ({
  type: GET_REPORT_ROLLING_RULES_ERROR,
  error,
})

export const deleteReportRollingRule = (id) => ({
  type: DELETE_REPORT_ROLLING_RULE,
  id,
})

export const deleteReportRollingRuleSuccess = (rollingRuleId) => ({
  type: DELETE_REPORT_ROLLING_RULE_SUCCESS,
  rollingRuleId,
})

export const createReportRollingRuleSuccess = (rollingRule) => ({
  type: CREATE_REPORT_ROLLING_RULE_SUCCESS,
  rollingRule,
})

export const createReportRollingRuleError = (error) => ({
  type: CREATE_REPORT_ROLLING_RULE_ERROR,
  error,
})

export const deleteAllReportRollingRules = (reportId) => ({
  type: DELETE_ALL_REPORT_ROLLING_RULES,
  reportId,
})

export const deleteAllReportRollingRulesSuccess = () => ({
  type: DELETE_ALL_REPORT_ROLLING_RULES_SUCCESS,
})

export const deleteAllReportRollingRulesError = (error) => ({
  type: DELETE_ALL_REPORT_ROLLING_RULES_ERROR,
  error,
})

export const setShowReportRollingRuleModal = (show) => ({
  type: SET_SHOW_REPORT_ROLLING_RULE_MODAL,
  show,
})

export const updateReportRollingRule = (rollingRule) => ({
  type: UPDATE_REPORT_ROLLING_RULE,
  rollingRule,
})

export const updateReportRollingRuleSuccess = ({ rollingRule, hide }) => ({
  type: UPDATE_REPORT_ROLLING_RULE_SUCCESS,
  rollingRule,
  hide,
})

export const updateReportRollingRuleError = (error) => ({
  type: UPDATE_REPORT_ROLLING_RULE_ERROR,
  error,
})

import { all, call, put, takeEvery } from 'redux-saga/effects'
import api from 'api/ReportRollingRulesApi'
import { handleError } from 'api/api-utils'
import {
  CREATE_REPORT_ROLLING_RULE,
  GET_REPORT_ROLLING_RULES,
  DELETE_REPORT_ROLLING_RULE,
  DELETE_ALL_REPORT_ROLLING_RULES,
  UPDATE_REPORT_ROLLING_RULE,
} from './constants'
import {
  getReportRollingRulesSuccess,
  getReportRollingRulesError,
  deleteReportRollingRuleSuccess,
  createReportRollingRuleSuccess,
  createReportRollingRuleError,
  deleteAllReportRollingRulesSuccess,
  deleteAllReportRollingRulesError,
  updateReportRollingRuleSuccess,
  updateReportRollingRuleError,
} from './actions'

export function* createReportRollingRule(action) {
  try {
    const rollingRule = yield call(api.createReportRollingRule, action)
    yield put(createReportRollingRuleSuccess(rollingRule))
  } catch (error) {
    yield put(handleError(error, createReportRollingRuleError))
  }
}

export function* getReportRollingRules(action) {
  const { reportId } = action
  try {
    const rollingRules = yield call(api.getReportRollingRules, reportId)
    yield put(getReportRollingRulesSuccess(rollingRules))
  } catch (error) {
    yield put(handleError(error, getReportRollingRulesError))
  }
}

export function* deleteReportRollingRule(action) {
  const { id } = action
  try {
    yield call(api.deleteReportRollingRule, id)
    yield put(deleteReportRollingRuleSuccess(id))
  } catch (error) {
    yield put(handleError(error, getReportRollingRulesError))
  }
}

export function* deleteAllReportRollingRules(action) {
  const { reportId } = action
  try {
    yield call(api.deleteAllReportRollingRules, reportId)
    yield put(deleteAllReportRollingRulesSuccess())
  } catch (error) {
    yield put(handleError(error, deleteAllReportRollingRulesError))
  }
}

export function* updateReportRollingRule(action) {
  try {
    const rollingRule = yield call(api.updateReportRollingRule, action)
    yield put(
      updateReportRollingRuleSuccess({
        rollingRule,
        hide: action.rollingRule.hide,
      })
    )
  } catch (error) {
    yield put(handleError(error, updateReportRollingRuleError))
  }
}

export function* reportRollingRuleSaga() {
  yield all([takeEvery(CREATE_REPORT_ROLLING_RULE, createReportRollingRule)])
  yield all([takeEvery(GET_REPORT_ROLLING_RULES, getReportRollingRules)])
  yield all([takeEvery(DELETE_REPORT_ROLLING_RULE, deleteReportRollingRule)])
  yield all([
    takeEvery(DELETE_ALL_REPORT_ROLLING_RULES, deleteAllReportRollingRules),
  ])
  yield all([takeEvery(UPDATE_REPORT_ROLLING_RULE, updateReportRollingRule)])
}

export default reportRollingRuleSaga

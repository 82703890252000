import PropTypes from 'prop-types'

import React, { useState, useEffect, useMemo } from 'react'
import { Multiselect } from 'react-widgets'
import Icon from 'react-widgets/lib/Icon'
import Select from 'react-widgets/lib/Select'
import WidgetPicker from 'react-widgets/lib/WidgetPicker'
import virtualize from 'react-widgets-virtualized'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { isEqual } from 'lodash'

import classNames from 'classnames'
import { GroupComponent, ListItem, TagItem } from './index'
import { CloseButton } from 'components/Button'
// import { getDimensionName } from 'containers/Dimension/functions.js'

import {
  getDimensionDataAsFlattenedArray,
  getSelectedValues,
  getDimensionsFilteredByRoot,
  getDimensionsFilteredByParent,
  getDisabledParentItems,
  valuesChanged,
} from './functions'
import {
  ALLOW_SIBLINGS_LOGIC, //reportingGroupForm uses this
  FILTER_SELECTED_ROOT_LOGIC,
  FILTER_TYPE_CUSTOM, //reportingGroupForm uses this
} from './constants'
import messages from './messages'
import useDimensionSearch from 'utils/hooks/useDimensionSearch'
import useDimensionValueFetchCheckStore from 'utils/hooks/useDimensionValueFetchCheckStore'
import { useParams } from 'react-router'

const VirtualMultiselect = virtualize(Multiselect) //https://github.com/jquense/react-widgets/tree/master/packages/virtualized

const filteredValuesLogics = {
  [FILTER_SELECTED_ROOT_LOGIC]: getDimensionsFilteredByRoot,
  [ALLOW_SIBLINGS_LOGIC]: getDimensionsFilteredByParent,
}

const disabledValuesLogics = {
  [FILTER_SELECTED_ROOT_LOGIC]: () => [],
  [ALLOW_SIBLINGS_LOGIC]: getDisabledParentItems,
}

const DimensionTool = ({
  selectedDimensionIds = [],
  selectionLogic = FILTER_SELECTED_ROOT_LOGIC,
  filter = 'contains',
  dimensionData,
  onSearchChange,
  disabled,
  dropUp,
  className,
  busy,
  disableValueFetch,
  onDimensionChange,
  intl: { formatMessage },
  redirect,
  includeOperative = false,
  placeholder,
  companyCode,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [customFilter, setCustomFilter] = useState('')
  const [searchFocused, setSearchFocused] = useState(false)

  const getFilteredValuesLogic = (selectionLogic) =>
    filteredValuesLogics[selectionLogic]
  const getDisabledValuesLogic = (selectionLogic) =>
    disabledValuesLogics[selectionLogic]

  const flattenedDimensionData = useMemo(
    () => getDimensionDataAsFlattenedArray(dimensionData),
    [dimensionData]
  )

  const [dimensionValues, setDimensionValues] = useState(
    getSelectedValues(selectedDimensionIds, flattenedDimensionData)
  )

  const [valuesLoading] = useDimensionValueFetchCheckStore(
    disableValueFetch ? [] : selectedDimensionIds
  )

  const { companyCode: companyParam } = useParams()
  companyCode = companyCode || companyParam
  const [setDimensionSearchTerm, searchLoading] = useDimensionSearch({
    companyCode,
    includeOperative,
  })
  const clippedValues = dimensionData?.some(
    (d) => d.valueCount !== d.values?.size
  )

  const filteredValues = useMemo(
    () =>
      getFilteredValuesLogic(selectionLogic)(
        dimensionValues,
        flattenedDimensionData
      ),
    [dimensionValues, flattenedDimensionData, selectionLogic]
  )

  const disabledValues = useMemo(
    () =>
      getDisabledValuesLogic(selectionLogic)(
        dimensionValues,
        flattenedDimensionData
      ),
    [dimensionValues, flattenedDimensionData, selectionLogic]
  )

  //Choose default dimensions if only 1 value available
  useEffect(() => {
    if (
      filteredValues.length === 1 &&
      redirect &&
      dimensionValues.length === 0
    ) {
      onDimensionChange(filteredValues[0].id)
    }
    // eslint-disable-next-line
  }, [])

  const [missingInitialValues, setMissing] = useState(true)
  // //We are still missing some parent given values and we got new dimension data
  useEffect(() => {
    if (missingInitialValues && selectedDimensionIds) {
      setDimensionValues(
        getSelectedValues(selectedDimensionIds, flattenedDimensionData)
      )
      setMissing(
        [...getSelectedValues(selectedDimensionIds, flattenedDimensionData)]
          .length !== [...selectedDimensionIds].length
      )
    }
  }, [dimensionData, missingInitialValues]) // eslint-disable-line

  //Update selected state if parent changes dimension selection for some reason
  useEffect(() => {
    if (!isEqual(selectedDimensionIds, dimensionValues.map((v) => v.id))) {
      setDimensionValues(
        getSelectedValues(selectedDimensionIds, flattenedDimensionData)
      )
    }
  }, [selectedDimensionIds]) // eslint-disable-line

  const getTextField = (item) => {
    if (!item.root) return ''
    const currentDimensionName = item.root
    const dimensionName = currentDimensionName
    if (!dimensionName) {
      return ''
    }
    return dimensionName.concat(' ', item.path.join(' '))
  }

  const handleChange = (values) => {
    setDimensionValues(values)
    onDimensionChange(values.map((v) => v.id), values)
    // This is to reduce prop.onDimensionChange calls if we think that user is possible still choosing more
    // However if we get component rerender while we have mismatch between dimensionValues and props.Values and no change called we lose the selection
    // const newValueOptions = getFilteredValuesLogic(selectionLogic)(
    //   values,
    //   flattenedDimensionData
    // )
    // //If nothing left to select apply selected dimensions
    // const currentIds = values.map((d) => d.id)
    // if (
    //   newValueOptions.length === 0 &&
    //   valuesChanged(currentIds, selectedDimensionIds)
    // ) {
    //   const keyedById = new Map(
    //     flattenedDimensionData.map((dim) => [dim.id, dim])
    //   )
    //   const currentDimensions = currentIds.map((id) => keyedById.get(id))
    //   onDimensionChange(currentIds, currentDimensions)
    // }
  }

  const handleCustomFilter = (customFilter) => {
    if (onSearchChange) onSearchChange(customFilter)
    if (companyCode && clippedValues) setDimensionSearchTerm(customFilter)
    setCustomFilter(customFilter)
  }

  // We have to be able to empty input field
  const handleSearch = (searchTerm) => {
    if (onSearchChange) onSearchChange(searchTerm)
    if (companyCode && clippedValues) setDimensionSearchTerm(searchTerm)
    setIsOpen(true)
    setSearchTerm(searchTerm)
  }

  // Multiselect sends Toggle-event on every change. Event is done with old state, so skipToggle can't be in state
  const handleToggle = (isOpening) => {
    if (searchFocused) {
      return
    }
    setIsOpen(isOpening)
    setCustomFilter('')
    const currentIds = dimensionValues.map((value) => value.id)
    if (!isOpening && valuesChanged(currentIds, selectedDimensionIds || [])) {
      const keyedById = new Map(
        flattenedDimensionData.map((dim) => [dim.id, dim])
      )
      const currentDimensions = currentIds.map((id) => keyedById.get(id))
      onDimensionChange(currentIds, currentDimensions)
    }
  }

  const handleClearSelections = () => {
    setDimensionValues([])
    onDimensionChange([], [])
  }

  const handleSearchFocus = () => {
    setSearchFocused(!searchFocused)
  }

  const applyCustomFilter = (values, filter) => {
    const filterInUpperCase = filter.toUpperCase()
    return values.filter(
      (v) =>
        v.path.some((p) => p.toUpperCase().includes(filterInUpperCase)) ||
        v.root.toUpperCase().includes(filterInUpperCase)
    )
  }

  const multiselectMessages = {
    emptyFilter: <FormattedMessage {...messages.emptyFilter} />,
    emptyList: <FormattedMessage {...messages.emptyList} />,
  }
  const closeButtonDisabled = dimensionValues.length === 0

  const valuesToSelect =
    filter === FILTER_TYPE_CUSTOM && customFilter !== ''
      ? applyCustomFilter(filteredValues, customFilter)
      : filteredValues

  const useCustomFilter = filter === FILTER_TYPE_CUSTOM
  return (
    <div className="dimensionTool">
      {!(disabled || closeButtonDisabled) && (
        <CloseButton
          aria-label={formatMessage(messages.clearSelection)}
          onClick={handleClearSelections}
          className="close-all-button"
        />
      )}
      <VirtualMultiselect
        type="uniform"
        className={classNames('multi-select', className, {
          customFilter: useCustomFilter,
          isOpen,
        })}
        // renderItem={this.renderItem} //ainakin filtteri tarvii tata
        initialIndex={2}
        useStaticSize
        useTranslate3d={false}
        length={valuesToSelect.length}
        value={dimensionValues}
        data={valuesToSelect}
        busy={busy || valuesLoading || searchLoading}
        disabled={disabled || disabledValues}
        filter={useCustomFilter ? false : filter}
        groupBy={(item) => [item.color, item.root]}
        groupComponent={GroupComponent}
        itemComponent={ListItem}
        messages={multiselectMessages}
        onChange={handleChange}
        onSearch={handleSearch}
        onToggle={handleToggle}
        open={isOpen}
        placeholder={placeholder || formatMessage(messages.placeholder)}
        searchTerm={useCustomFilter ? undefined : searchTerm}
        tagComponent={(item) => <TagItem {...item} />}
        textField={getTextField}
        valueField="id"
        dropUp={dropUp === true}
      />
      {useCustomFilter && isOpen && (
        <WidgetPicker className="search">
          <input
            value={customFilter}
            onFocus={handleSearchFocus}
            onBlur={handleSearchFocus}
            onKeyUp={(e) => {
              // prevents MultiSelect default behaviour, backspace navigates to the previous input field
              e.stopPropagation()
            }}
            onKeyDown={(e) => {
              // prevents MultiSelect default behaviour, backspace navigates to the previous input field
              e.stopPropagation()
            }}
            onChange={(e) => {
              handleCustomFilter(e.target.value)
            }}
            placeholder={formatMessage(messages.customFilterPlaceholder)}
          />
          <Select
            icon={Icon({ icon: 'search' })}
            role="presentation"
            aria-hidden="true"
          />
        </WidgetPicker>
      )}
    </div>
  )
}

DimensionTool.propTypes = {
  dimensionData: PropTypes.object,
  intl: intlShape.isRequired,
  onDimensionChange: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  selectedDimensionIds: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  redirect: PropTypes.bool,
  dropUp: PropTypes.bool,
  className: PropTypes.string,
  selectionLogic: PropTypes.oneOf([
    FILTER_SELECTED_ROOT_LOGIC,
    ALLOW_SIBLINGS_LOGIC,
  ]),
  filter: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default injectIntl(DimensionTool)

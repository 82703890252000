import { fromJS, List } from 'immutable'

import {
  CREATE_REPORT_ROLLING_RULE,
  CREATE_REPORT_ROLLING_RULE_SUCCESS,
  CREATE_REPORT_ROLLING_RULE_ERROR,
  GET_REPORT_ROLLING_RULES,
  GET_REPORT_ROLLING_RULES_SUCCESS,
  GET_REPORT_ROLLING_RULES_ERROR,
  DELETE_REPORT_ROLLING_RULE,
  DELETE_REPORT_ROLLING_RULE_SUCCESS,
  DELETE_REPORT_ROLLING_RULE_ERROR,
  DELETE_ALL_REPORT_ROLLING_RULES,
  DELETE_ALL_REPORT_ROLLING_RULES_SUCCESS,
  DELETE_ALL_REPORT_ROLLING_RULES_ERROR,
  SET_SHOW_REPORT_ROLLING_RULE_MODAL,
  UPDATE_REPORT_ROLLING_RULE,
  UPDATE_REPORT_ROLLING_RULE_SUCCESS,
  UPDATE_REPORT_ROLLING_RULE_ERROR,
} from './constants'

const initialState = fromJS({
  reportRollingRules: undefined,
  error: false,
  loading: false,
  dimensionsLoading: false,
  rollingRulesListLoading: false,
})

const reportRollingRulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_REPORT_ROLLING_RULE:
    case DELETE_REPORT_ROLLING_RULE:
    case DELETE_ALL_REPORT_ROLLING_RULES:
    case UPDATE_REPORT_ROLLING_RULE:
      return state.set('loading', true).set('error', false)
    case GET_REPORT_ROLLING_RULES:
      return state.set('rollingRulesListLoading', true).set('error', false)
    case CREATE_REPORT_ROLLING_RULE_SUCCESS:
      return state
        .set('loading', false)
        .set(
          'reportRollingRules',
          state.get('reportRollingRules').concat(action.rollingRule)
        )
        .set('showReportRollingRuleModal', false)
    case GET_REPORT_ROLLING_RULES_SUCCESS:
      return state
        .set('loading', false)
        .set('rollingRulesListLoading', false)
        .set('reportRollingRules', List(action.rollingRules))
    case GET_REPORT_ROLLING_RULES_ERROR:
    case DELETE_REPORT_ROLLING_RULE_ERROR:
    case CREATE_REPORT_ROLLING_RULE_ERROR:
    case DELETE_ALL_REPORT_ROLLING_RULES_ERROR:
    case UPDATE_REPORT_ROLLING_RULE_ERROR:
      return state
        .set('loading', false)
        .set('rollingRulesListLoading', false)
        .set('error', action.error)
    case DELETE_REPORT_ROLLING_RULE_SUCCESS:
      return state
        .set('loading', false)
        .set(
          'reportRollingRules',
          state
            .get('reportRollingRules')
            .filter((r) => r.id !== action.rollingRuleId)
        )
    case DELETE_ALL_REPORT_ROLLING_RULES_SUCCESS:
      return state.set('loading', false).set('reportRollingRules', List())

    case SET_SHOW_REPORT_ROLLING_RULE_MODAL:
      return state.set('showReportRollingRuleModal', action.show)

    case UPDATE_REPORT_ROLLING_RULE_SUCCESS:
      action.hide()
      return state
        .set('loading', false)
        .set(
          'reportRollingRules',
          state
            .get('reportRollingRules')
            .map((r) =>
              r.id === action.rollingRule.id ? action.rollingRule : r
            )
        )
    default:
      return state
  }
}

export default reportRollingRulesReducer

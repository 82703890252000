import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const baseUrl = 'reportrollingrules'

const createReportRollingRule = (action) => {
  const url = 'reportrollingrules'

  return axios
    .post(url, action, config())
    .then(returnData)
    .catch(catchError)
}

const getReportRollingRules = (reportId) => {
  return axios
    .get(`${baseUrl}/list/${reportId}`, config())
    .then(returnData)
    .catch(catchError)
}

const deleteReportRollingRule = (id) => {
  return axios
    .delete(`${baseUrl}/delete/${id}`, config())
    .then(returnData)
    .catch(catchError)
}

const deleteAllReportRollingRules = (reportId) => {
  return axios
    .delete(`${baseUrl}/all/${reportId}`, config())
    .then(returnData)
    .catch(catchError)
}

const updateReportRollingRule = (action) => {
  const { rollingRule } = action
  return axios
    .put(`${baseUrl}/${rollingRule.id}`, rollingRule, config())
    .then(returnData)
    .catch(catchError)
}

const rollingRuleApi = {
  createReportRollingRule,
  getReportRollingRules,
  deleteReportRollingRule,
  deleteAllReportRollingRules,
  updateReportRollingRule,
}

export default rollingRuleApi

import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import contentsApi from 'api/ContentApi'

import {
  getContentsError,
  getContentsSuccess,
  createContentError,
  createContentSuccess,
  updateContentError,
  updateContentSuccess,
  deleteContentError,
  deleteContentSuccess,
} from './actions'
import {
  GET_CONTENTS,
  CREATE_CONTENT,
  DELETE_CONTENT,
  UPDATE_CONTENT,
} from './constants'

export function* getContents(action) {
  const { contentType, targetId } = action
  try {
    const contents = yield call(contentsApi.getContents, {
      type: contentType,
      targetId,
    })
    yield put(getContentsSuccess({ contents, contentType, targetId }))
  } catch (error) {
    yield put(
      getContentsError({
        error,
        targetId,
        contentType,
      })
    )
  }
}

export function* deleteContent(action) {
  const { contentId, targetId, contentType, parentTargetId } = action

  try {
    yield call(contentsApi.deleteContent, contentId)
    yield put(
      deleteContentSuccess({ contentId, targetId, contentType, parentTargetId })
    )
  } catch (error) {
    yield put(
      deleteContentError({
        error,
        contentId,
        targetId,
        contentType,
        parentTargetId,
      })
    )
  }
}

export function* createContent(action) {
  const {
    contentType,
    targetId,
    data,
    temporaryContentId,
    parentTargetId,
    dimensionValueIds,
  } = action
  try {
    const createdContent = yield call(contentsApi.createContent, {
      type: contentType,
      targetId,
      data: JSON.stringify(data, null, 4),
      dimensionValueIds,
    })
    yield put(
      createContentSuccess({
        content: createdContent,
        contentType,
        targetId,
        temporaryContentId,
        parentTargetId,
      })
    )
  } catch (error) {
    yield put(
      createContentError({
        contentType,
        error,
        targetId,
        temporaryContentId,
        parentTargetId,
      })
    )
  }
}

export function* updateContent(action) {
  const { contentId, data, contentType, targetId } = action

  try {
    yield call(contentsApi.updateContent, {
      id: contentId,
      data: JSON.stringify(data, null, 4),
    })
    yield put(updateContentSuccess({ contentId, data, contentType, targetId }))
  } catch (error) {
    yield put(
      updateContentError({
        error,
        contentId,
        contentType,
        targetId,
      })
    )
  }
}

export function* contentSagas() {
  yield all([
    takeEvery(GET_CONTENTS, getContents),
    takeLatest(CREATE_CONTENT, createContent),
    takeLatest(UPDATE_CONTENT, updateContent),
    takeLatest(DELETE_CONTENT, deleteContent),
  ])
}

export default contentSagas

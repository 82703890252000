/*
 *
 * ReportScheme reducer
 *
 */
import { fromJS, List, Set } from 'immutable'

import { ReportSchemeRowGroupRecord, ReportSchemeRowRecord } from 'records'
import {
  COPY_REPORT_SCHEME_ROW,
  COPY_REPORT_SCHEME_ROW_SUCCESS,
  COPY_REPORT_SCHEME_ROW_ERROR,
  CREATE_REPORT_SCHEME_ROW,
  CREATE_REPORT_SCHEME_ROW_ERROR,
  CREATE_REPORT_SCHEME_ROW_SUCCESS,
  DELETE_REPORT_SCHEME_ROW,
  DELETE_REPORT_SCHEME_ROW_ERROR,
  DELETE_REPORT_SCHEME_ROW_SUCCESS,
  GET_REPORT_SCHEME_TREE,
  GET_REPORT_SCHEME_TREE_ERROR,
  GET_REPORT_SCHEME_TREE_SUCCESS,
  MOVE_REPORT_SCHEME_ROW,
  MOVE_REPORT_SCHEME_ROW_ERROR,
  MOVE_REPORT_SCHEME_ROW_SUCCESS,
  TOGGLE_COLLAPSE_ALL_GROUPS,
  TOGGLE_COLLAPSE_GROUP,
  TOGGLE_COLLAPSE_GROUPS,
  TOGGLE_PERCENTAGE_TARGET,
  TOGGLE_PERCENTAGE_TARGET_ERROR,
  TOGGLE_PERCENTAGE_TARGET_SUCCESS,
  TOGGLE_SHOW_FORM,
  TOGGLE_SHOW_SETTINGS_FORM,
  UPDATE_REPORT_SCHEME_ROW,
  UPDATE_REPORT_SCHEME_ROW_ERROR,
  UPDATE_REPORT_SCHEME_ROW_SUCCESS,
} from './constants'

const initialState = fromJS({
  allGroupsCollapsed: true,
  error: false,
  groupIds: new Set(),
  id: undefined,
  loading: false, // loading is set to true when appropriate action is handled.
  openGroupIds: new Set(),
  showForm: undefined,
  tree: undefined,
  showSettingsForm: false,
})

const getGroupIds = (node) => {
  if (node.children) {
    const flattenedChildren = node.children.flatMap(getGroupIds)
    const flattenedChildrenArray = flattenedChildren.toArray
      ? flattenedChildren.toArray()
      : []
    return Set([node.id].concat(flattenedChildrenArray))
  }
  return undefined
}

// turn record path string into keyPath array ['tree', '1', 'children']
const getKeyPath = (path) => [
  'tree',
  ...path
    .toLowerCase()
    .split('/')
    .slice(2),
]

const mapReportSchemeTree = ({
  node,
  openGroupIds = new Set(),
  level = 0,
  schemeId,
}) => {
  if (node.children) {
    return new ReportSchemeRowGroupRecord(node)
      .set(
        'children',
        List(
          node.children.map((childNode) =>
            mapReportSchemeTree({
              node: childNode,
              openGroupIds,
              level: level + 1,
            })
          )
        )
      )
      .set('schemeId', schemeId)
      .set('level', level)
      .set('isCollapsed', !openGroupIds.includes(node.id))
  }

  return new ReportSchemeRowRecord(node).set('level', level)
}

const reportSchemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case COPY_REPORT_SCHEME_ROW:
    case MOVE_REPORT_SCHEME_ROW:
    case CREATE_REPORT_SCHEME_ROW:
    case UPDATE_REPORT_SCHEME_ROW: {
      return state.set('loading', true).set('showForm', undefined)
    }
    case COPY_REPORT_SCHEME_ROW_ERROR:
    case CREATE_REPORT_SCHEME_ROW_ERROR:
    case DELETE_REPORT_SCHEME_ROW_ERROR:
    case GET_REPORT_SCHEME_TREE_ERROR:
    case MOVE_REPORT_SCHEME_ROW_ERROR:
    case TOGGLE_PERCENTAGE_TARGET_ERROR:
    case UPDATE_REPORT_SCHEME_ROW_ERROR:
      return state.set('loading', false).set('error', action.error)

    case COPY_REPORT_SCHEME_ROW_SUCCESS:
    case MOVE_REPORT_SCHEME_ROW_SUCCESS:
    case CREATE_REPORT_SCHEME_ROW_SUCCESS:
    case DELETE_REPORT_SCHEME_ROW_SUCCESS:
    case GET_REPORT_SCHEME_TREE_SUCCESS:
    case UPDATE_REPORT_SCHEME_ROW_SUCCESS: {
      const tree = mapReportSchemeTree({
        node: action.tree.schemeGroup,
        openGroupIds: state.get('openGroupIds'),
        schemeId: action.tree.schemeId,
      })
      return state
        .set('loading', false)
        .set('showForm', undefined)
        .set('tree', tree)
        .set('groupIds', getGroupIds(tree))
    }

    case DELETE_REPORT_SCHEME_ROW:
    case TOGGLE_PERCENTAGE_TARGET:
    case GET_REPORT_SCHEME_TREE:
      return state.set('loading', true)

    case TOGGLE_COLLAPSE_ALL_GROUPS:
      return state
        .set(
          'tree',
          mapReportSchemeTree({
            node: action.tree,
            openGroupIds: action.openGroupIds,
          })
        )
        .set('openGroupIds', action.openGroupIds || new Set())

    case TOGGLE_COLLAPSE_GROUP: {
      const { path, id, value } = action
      const keyPath = getKeyPath(path)
      const openGroupIds = state.get('openGroupIds') || new Set()

      return state
        .setIn([...keyPath, 'isCollapsed'], value)
        .set(
          'openGroupIds',
          value ? openGroupIds.delete(id) : openGroupIds.add(id)
        )
    }

    case TOGGLE_COLLAPSE_GROUPS: {
      const { rowIds, tree } = action
      const openGroupIds = getGroupIds(tree).filter((value) =>
        rowIds.includes(value)
      )
      return state
        .set(
          'tree',
          mapReportSchemeTree({
            node: tree,
            openGroupIds,
          })
        )
        .set('openGroupIds', openGroupIds)
    }

    case TOGGLE_PERCENTAGE_TARGET_SUCCESS: {
      return state.set('loading', false)
    }
    case TOGGLE_SHOW_FORM: {
      const { edit, form, id, index, path } = action

      return state.set(
        'showForm',
        id
          ? {
              edit,
              form,
              id,
              index,
              path,
            }
          : undefined
      )
    }
    case TOGGLE_SHOW_SETTINGS_FORM: {
      const toggleStatus = state.get('showSettingsForm')
      return state.set('showSettingsForm', !toggleStatus)
    }

    default:
      return state
  }
}

export default reportSchemeReducer

export const CREATE_REPORT_ROLLING_RULE =
  'app/ReportRollingRules/CREATE_REPORT_ROLLING_RULE'
export const CREATE_REPORT_ROLLING_RULE_SUCCESS =
  'app/ReportRollingRules/CREATE_REPORT_ROLLING_RULE_SUCCESS'
export const CREATE_REPORT_ROLLING_RULE_ERROR =
  'app/ReportRollingRules/CREATE_REPORT_ROLLING_RULE_ERROR'
export const GET_REPORT_ROLLING_RULES =
  'app/ReportRollingRules/GET_REPORT_ROLLING_RULES'
export const GET_REPORT_ROLLING_RULES_SUCCESS =
  'app/ReportRollingRules/GET_REPORT_ROLLING_RULES_SUCCESS'
export const GET_REPORT_ROLLING_RULES_ERROR =
  'app/ReportRollingRules/GET_REPORT_ROLLING_RULES_ERROR'

export const DELETE_REPORT_ROLLING_RULE =
  'app/ReportRollingRules/DELETE_REPORT_ROLLING_RULE'

export const DELETE_REPORT_ROLLING_RULE_SUCCESS =
  'app/ReportRollingRules/DELETE_REPORT_ROLLING_RULE_SUCCESS'

export const DELETE_REPORT_ROLLING_RULE_ERROR =
  'app/ReportRollingRules/DELETE_REPORT_ROLLING_RULE_ERROR'

export const DELETE_ALL_REPORT_ROLLING_RULES =
  'app/ReportRollingRules/DELETE_ALL_REPORT_ROLLING_RULES'

export const DELETE_ALL_REPORT_ROLLING_RULES_SUCCESS =
  'app/ReportRollingRules/DELETE_ALL_REPORT_ROLLING_RULES_SUCCESS'

export const DELETE_ALL_REPORT_ROLLING_RULES_ERROR =
  'app/ReportRollingRules/DELETE_ALL_REPORT_ROLLING_RULES_ERROR'

export const SET_SHOW_REPORT_ROLLING_RULE_MODAL =
  'app/ReportRollingRules/SET_SHOW_REPORT_ROLLING_RULE_MODAL'

export const UPDATE_REPORT_ROLLING_RULE =
  'app/ReportRollingRules/UPDATE_REPORT_ROLLING_RULE'

export const UPDATE_REPORT_ROLLING_RULE_SUCCESS =
  'app/ReportRollingRules/UPDATE_REPORT_ROLLING_RULE_SUCCESS'

export const UPDATE_REPORT_ROLLING_RULE_ERROR =
  'app/ReportRollingRules/UPDATE_REPORT_ROLLING_RULE_ERROR'

import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const getDimensions = (companyCode, includeOperative, search, limit) =>
  axios
    .get(
      companyCode
        ? `${companyCode}/dimensions/internal?includeOperative=${includeOperative}${
            search ? `&search=${search}` : ''
          }${limit ? `&limit=${limit}` : ''}`
        : 'dimensions',
      config()
    )
    .then(returnData)
    .catch(catchError)

const getDimension = (companyCode, dimensionId) =>
  axios
    .get(`${companyCode}/dimensions/${dimensionId}`, config())
    .then(returnData)
    .catch(catchError)

const createDimension = (companyCode, dimension) =>
  axios
    .post(`${companyCode}/dimensions`, dimension, config())
    .then(returnData)
    .catch(catchError)

const deleteDimension = (companyCode, id) =>
  axios
    .delete(`${companyCode}/dimensions/${id}`, config())
    .then(returnData)
    .catch(catchError)

const patchDimension = (companyCode, id, patch) =>
  axios
    .patch(`${companyCode}/dimensions/${id}`, patch, config())
    .then(returnData)
    .catch(catchError)

const deleteDimensionValue = (companyCode, dimensionValueId) =>
  axios
    .delete(`${companyCode}/dimensionValues/${dimensionValueId}`, config())
    .then()
    .catch(catchError)

const createDimensionValue = (companyCode, dimensionValue) =>
  axios
    .post(`${companyCode}/dimensionValues`, dimensionValue, config())
    .then(returnData)
    .catch(catchError)

const getDimensionValue = (dimensionValueId) =>
  axios
    .get(`dimensionValues/${dimensionValueId}`, config())
    .then((resp) => [
      returnData(resp),
      resp.data.relationships.company.data.code,
    ])
    .catch(catchError)

const patchDimensionValue = (companyCode, id, patch) =>
  axios
    .patch(`${companyCode}/dimensionValues/${id}`, patch, config())
    .then(returnData)
    .catch(catchError)

const getDimensionTargets = (dimensionTargetIds) => {
  return axios
    .post(`dimensiontargets/list`, dimensionTargetIds, config())
    .then(returnData)
    .catch(catchError)
}

const dimensionsApi = {
  createDimension,
  createDimensionValue,
  deleteDimension,
  deleteDimensionValue,
  getDimensions,
  getDimension,
  getDimensionValue,
  patchDimension,
  patchDimensionValue,
  getDimensionTargets,
}

export default dimensionsApi

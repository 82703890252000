import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const getContents = ({ type, targetId }) =>
  axios
    .get(`/content/${type}/${targetId}`, config())
    .then(returnData)
    .catch(catchError)

const createContent = ({ data, type, targetId, dimensionValueIds }) =>
  axios
    .post('/content', { data, type, targetId, dimensionValueIds }, config())
    .then(returnData)
    .catch(catchError)

const updateContent = ({ id, data }) =>
  axios
    .put(`/content/${id}`, { content: data }, config())
    .then(returnData)
    .catch(catchError)

const deleteContent = (id) =>
  axios
    .delete(`/content/${id}`, config())
    .then(returnData)
    .catch(catchError)

const contentApi = {
  getContents,
  createContent,
  updateContent,
  deleteContent,
}

export default contentApi

/*
 *
 * Budget functions
 *
 */
import _ from 'lodash'
import queryString from 'query-string'

import { FILTER_UNUSED_ACCOUNTS } from './constants'
import {
  CONTENT_TYPE_BUDGET_ROW,
  CONTENT_TYPE_SUB_BUDGET_ROW,
} from 'containers/Content/constants'
import { List, Set } from 'immutable'
import { BudgetDataRecord, SchemeGroupRecord } from 'records'

export const getDimensionsArray = (dimensions) => {
  if (dimensions) {
    return dimensions.constructor === Array ? dimensions : [dimensions]
  }

  return []
}

export const applyFilters = ({ tree, filters, accountGroup }) => {
  let filteredTree = tree
  if (!tree || !tree.accountGroupType) {
    return tree
  }

  if (tree.accountGroupType !== accountGroup && accountGroup !== 'All') {
    filteredTree = filterRecursive(
      tree,
      (e) =>
        e.accountGroupType === accountGroup || e.accountGroupType === 'Subtotal'
    )
    if (!filteredTree.children.isEmpty())
      filteredTree = filteredTree.children.first()
  }

  filters.forEach((filter) => {
    switch (filter) {
      case FILTER_UNUSED_ACCOUNTS:
        filteredTree = filterRecursive(
          filteredTree,
          (e) => e.hasData || e.accountHasVoucherData
        )
        return
      default:
        throw new Error(`Filter ${filter} not found`)
    }
  })
  return filteredTree
}

const filterRecursive = (tree, filter) => {
  const newChildren = tree.children.filter((e) => filter(e))
  return tree.set(
    'children',
    newChildren.map((c) => filterRecursive(c, filter))
  )
}

export const byIdAndDimensionTargetAndType = (
  note,
  row,
  selectedDimensionValueIds,
  contentType
) =>
  note.dimensionTarget &&
  note.dimensionTarget.dimensionValueIds &&
  note.dimensionTarget.dimensionValueIds.size > 0
    ? note.type === contentType &&
      note.targetId === row.id.toString() &&
      note.dimensionTarget.dimensionValueIds.every((dvId) =>
        selectedDimensionValueIds.includes(dvId)
      )
    : note.type === contentType && note.targetId === row.id.toString()

export const resolveNoteTarget = ({
  note,
  flatBudgetTreeRows,
  flatSubBudgetTreeRows,
}) => {
  if (note.type === CONTENT_TYPE_BUDGET_ROW) {
    return flatBudgetTreeRows.find((row) => row.id.toString() === note.targetId)
  } else if (note.type === CONTENT_TYPE_SUB_BUDGET_ROW) {
    const flattenedSubBudgetTreeRows = _.flatten(
      flatSubBudgetTreeRows.toList().toJS()
    )
    return flattenedSubBudgetTreeRows.find(
      (row) => row.id.toString() === note.targetId
    )
  }
  return undefined
}

export const mapDataIntoQuarters = ({ data, openQuarters = [] }) => {
  const newColumns = data.toJS().columns.reduce((result, col) => {
    if (!!col.period.q && !!col.period.y) {
      const i = result.findIndex(
        (r) => r.title === `Q${col.period.q} / ${col.period.y}`
      )
      if (i >= 0) {
        result[i].id.push(col.id)
        if (!col.editable && result[i].editable)
          result[i].editable = col.editable //make editable false if a month is false
        if (col.lastLockedMonth && !result[i].lastLockedMonth)
          result[i].lastLockedMonth = col.title
      } else {
        result.push({
          title: `Q${col.period.q} / ${col.period.y}`,
          id: [col.id],
          type: 'Quarter',
          editable: col.editable, //this should be false, if any month is false?
          period: { q: col.period.q, y: col.period.y },
          lastLockedMonth: col.lastLockedMonth && col.title,
        })
      }
    } else {
      result.push(col)
    }
    if (
      openQuarters.some((q) =>
        _.isEqual(q, { q: col.period.q, y: col.period.y })
      )
    ) {
      result.push(col)
    }
    return result
  }, [])
  return new BudgetDataRecord({
    ...data.toJS(),
    rows: data.rows,
    columns: List(newColumns),
  })
}

export const mapDataIntoTertiles = ({ data, openTertiles = [] }) => {
  const newColumns = data.toJS().columns.reduce((result, col) => {
    if (!!col.period.t && !!col.period.y) {
      const i = result.findIndex(
        (r) => r.title === `T${col.period.t} / ${col.period.y}`
      )
      if (i >= 0) {
        result[i].id.push(col.id)
        if (!col.editable && result[i].editable)
          result[i].editable = col.editable //make editable false if a month is false
        if (col.lastLockedMonth && !result[i].lastLockedMonth)
          result[i].lastLockedMonth = col.title
      } else {
        result.push({
          title: `T${col.period.t} / ${col.period.y}`,
          id: [col.id],
          type: 'Tertile',
          editable: col.editable, //this should be false, if any month is false?
          period: { t: col.period.t, y: col.period.y },
          lastLockedMonth: col.lastLockedMonth && col.title,
        })
      }
    } else {
      result.push(col)
    }
    if (
      openTertiles.some((t) =>
        _.isEqual(t, { t: col.period.t, y: col.period.y })
      )
    ) {
      result.push(col)
    }
    return result
  }, [])
  return new BudgetDataRecord({
    ...data.toJS(),
    rows: data.rows,
    columns: List(newColumns),
  })
}

export const mapDataIntoYears = ({ data, openYears = [] }) => {
  const newColumns = data.toJS().columns.reduce((result, col) => {
    if (!!col.period.y) {
      const i = result.findIndex((r) => r.title === `${col.period.y}`)
      if (i >= 0) {
        result[i].id.push(col.id)
        if (!col.editable && result[i].editable)
          result[i].editable = col.editable
        if (col.lastLockedMonth && !result[i].lastLockedMonth)
          result[i].lastLockedMonth = col.title
      } else {
        result.push({
          title: `${col.period.y}`,
          id: [col.id],
          type: 'Year',
          editable: col.editable,
          period: { y: col.period.y },
          lastLockedMonth: col.lastLockedMonth && col.title,
        })
      }
    } else {
      result.push(col)
    }
    if (openYears.includes(col.period.y)) {
      result.push(col)
    }
    return result
  }, [])
  return new BudgetDataRecord({
    ...data.toJS(),
    rows: data.rows,
    columns: List(newColumns),
  })
}
export const parseDvFromQuery = (urlProps) => {
  if (urlProps.location?.search) {
    const values = queryString.parse(urlProps.location.search, {
      parseNumbers: true,
    })
    return values ? values.dv : []
  }
  return []
}

const isRowEditable = (schemeRow, query) => {
  const { rollingInputDimensionTarget, editable } = schemeRow

  if (rollingInputDimensionTarget == null) {
    return editable
  }
  // Compare set of dimensionValueIds. If rollingInputDimensionTarget is an exact match to current query,
  // allow editing for the row.
  const inputSet = Set(
    rollingInputDimensionTarget.dimensionValues.map((dv) => dv.id)
  )
  const selectedSet = Set(query.dv)

  return inputSet.equals(selectedSet)
}

const mapBudgetSchemeGroup = (schemeGroup, query, level = 0) =>
  new SchemeGroupRecord({ ...schemeGroup, level })
    .set(
      'children',
      List(
        schemeGroup.children.map((child) =>
          mapBudgetSchemeGroup(child, query, level + 1)
        )
      )
    )
    .set('editable', isRowEditable(schemeGroup, query))

export const mapBudgetTree = (schemeGroup, query) =>
  mapBudgetSchemeGroup(schemeGroup, query)
